
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.mobile-container {
    background-color: rgb(196, 155, 108);
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
}
#note {
    font-size: 32px;
    font-weight: 600;
    color: white;
}
#border {
    background-image: url(./assets/iphone_shell.png);
    background-size: 100% 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#fake-mobile {
    background-color: #cba77e;
    width: 320px;
    height: 675px;
    border-radius: 40px;
    transform: translate(0);
    overflow: auto;
    position: relative;
}
::-webkit-scrollbar {
    width: 6px;
    display: none;
}
::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-track {
    background-color: rgb(196, 155, 108);
}
